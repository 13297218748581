@import 'themes-vars.module.scss';
@import '~react-perfect-scrollbar/dist/css/styles.css';

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

.apexcharts-legend-marker {
    margin-right: 15px !important;
}

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

.syntax-high-lighter {
    background-color: inherit !important;
}
body .ql-toolbar.ql-snow,
.ql-toolbar.ql-snow + .ql-container.ql-snow,
body .rdw-editor-toolbar,
.editorClassName {
    border: 1px solid $blue50;
}
.editorClassName {
    padding: 5px 18px;
}
.MuiPickersBasePicker-pickerView {
    .MuiPickersClockNumber-clockNumber,
    .MuiPickersCalendarHeader-dayLabel {
        color: $grey500;
    }
}
.MuiTabs-flexContainer {
    border-bottom: 1px solid $blue50;
}
#simple-popper[role="tooltip"]{
    z-index: 1201;
}
.centeredHeaderCell span {
    justify-content: center;
}
.centeredHeaderCell span button {
    margin-right: 0;
    margin-left: 0px; 
}